<template>
   <div id="originSelectWrapper">
      <div v-if="!hideFilter" v-on-clickaway="closeOriginSelect">
         <h4>
            <span v-html="getTitle()"></span>
            <a
               class="originSelectLink"
               ref="originSelectLink"
               @click="openOriginSelect"
               tabindex="0"
            >
               <div class="originSelectLink__inner">
                  <select
                     ref="nativeInput"
                     id="originSelectorNative"
                     @change="originChangedSelect"
                  >
                     <option
                        v-for="origin in flightOrigins"
                        v-bind:key="origin.iata"
                        :value="origin.iata"
                     >
                        {{ origin.name }}
                     </option>
                  </select>
                  <svg class="locationMarkerTitle">
                     <use href="#location-picker"></use>
                  </svg>
                  {{ chosenOriginName }}
               </div>

               <svg class="chevronDown" width="24" height="24">
                  <use href="#chevron-down"></use>
               </svg>
            </a>
         </h4>
         <div
            id="originSelector"
            ref="originSelector"
            :class="{ open: isOpen }"
         >
            <div class="originChoices">
               <span v-for="origin in flightOrigins" v-bind:key="origin.iata">
                  <a
                     href="#"
                     :data-name="origin.name"
                     :data-iata="origin.iata"
                     @click="originChanged"
                  >
                     {{ origin.name }} ({{ origin.iata }})
                  </a>
               </span>
            </div>
         </div>
      </div>
      <div v-else>
         <h4 v-html="getTitle()"></h4>
      </div>
   </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
   mixins: [clickaway],
   name: "OriginSelectorOneClick",
   props: [
      "chosenOrigin",
      "chosenOriginName",
      "flightOrigins",
      "title",
      "hideFilter",
   ],
   data: () => ({
      currentOrigin: "",
      currentOriginName: "",
      isOpen: false,
      resizeTimeout: null,
   }),
   methods: {
      getTitle() {
         if (this.title === "") {
            return "Flight specials from ";
         } else {
            return this.title;
         }
      },
      originChanged(event) {
         event.preventDefault();
         var link = event.target;
         this.currentOrigin = link.dataset.iata;
         this.currentOriginName = link.dataset.name;
         this.$emit("updateOrigin", this.currentOrigin, this.currentOriginName);
         this.closeOriginSelect();
      },
      originChangedSelect(event) {
         var select = event.srcElement;
         var selectedOption = select.options[select.options.selectedIndex];

         this.currentOrigin = select.value;
         this.currentOriginName = selectedOption.text;

         this.$emit("updateOrigin", this.currentOrigin, this.currentOriginName);
      },
      openOriginSelect() {
         // Close the origin select if it's already open
         if (this.isOpen) {
            this.closeOriginSelect();
         } else {
            // if native input is hidden (display=none)
            if (!this.$refs.nativeInput.offsetWidth) {
               this.openMainOriginSelect();
            }
         }
      },
      openMainOriginSelect() {
         this.isOpen = true;
      },
      closeOriginSelect() {
         this.isOpen = false;
      },
      positionOriginSelector() {
         const originSelectLink = this.$refs.originSelectLink;
         const originSelector = this.$refs.originSelector;

         if (originSelectLink && originSelector) {
            const linkRectLeft = originSelectLink.offsetLeft;

            // Set the position of origin-selector
            originSelector.style.left = `${linkRectLeft}px`;
         }
      },
      debounce(func, wait) {
         let timeout;
         return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
         };
      },
   },
   mounted() {
      this.positionOriginSelector();
      this.debouncedResizeListener = this.debounce(
         this.positionOriginSelector,
         200
      );
      window.addEventListener("resize", this.debouncedResizeListener);
   },
   beforeDestroy() {
      window.removeEventListener("resize", this.debouncedResizeListener);
   },
};
</script>

<style lang="scss">
@import "../styles/variables";

#originSelectWrapper {
   position: relative;
   display: inline-block;
   width: 100%;
   text-align: left;
   margin-bottom: 2.25em;

   >div {
      display: inline-flex;
      position: relative;
   }

   h4 {
      font-family: $font-heading;
      color: $va-purple-dark;
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      row-gap: 8px;
      column-gap: 16px;
      flex-wrap: wrap;

      span {
         font-weight: 500;
         font-size: 27px;
         line-height: 34px;

         @include bp("small") {
            font-size: 22px;
            line-height: 28px;
         }
      }
   }
}

.locationMarkerTitle {
   fill: $va-red;
   width: 1.25em;
   height: 1.25em;
   vertical-align: middle;
}

.originSelectLink {
   line-height: 23px;
   font-size: 18px;
   font-weight: 500;
   cursor: pointer;
   position: relative;
   display: inline-block;
   /* to allow native select 100% width */
   transition: background 0.3s, border-color 0.3s, box-shadow 0.3s;
   border-radius: 4px;
   display: inline-flex;
   align-items: center;
   justify-content: space-between;
   gap: 8px;
   background: #fff;
   color: $va-purple-dark;
   border: 1px solid $va-grey-light;
   padding: 0.75em 1em;
   text-decoration: none;

   &:hover {
      border-color: #bfbfc0;
   }

   &:active {
      border-color: #8e8d8f;
   }

   &:focus,
   &:focus-within {
      outline: none;
      border-color: #cd0c0c;
      box-shadow: 0px 0px 0px 4px #fef3f3;

      svg.chevron-down {
         transform: rotate(180deg);
      }
   }

   @include bp("small") {
      width: 100%;
      // justify-content: space-between;
      margin-right: 24px;
   }

   .originSelectLink__inner {
      display: flex;
      gap: 8px;
   }

   svg.chevronDown {
      transform: rotate(0);
      transition: transform 0.3s ease-in-out;
      margin-left: 1em;
   }
}

#originSelectorNative {
   opacity: 0;
   position: absolute;
   left: 0;
   right: 0;
   bottom: 0;
   top: 0;
   max-width: 100%;
   width: 100%;
   pointer-events: all;

   display: none;

   @media screen and (max-width: 66em) {
      display: block;
   }
}

#originSelector {
   position: absolute;
   // left: 22.25em !important;
   left: 17.75em !important;
   top: 4em;
   display: none;
   z-index: 2;
   max-height: 14.125em;
   min-width: 18em;
   overflow-y: auto;
   border-radius: 4px;
   background: $white;
   border: 1px solid $va-grey-light;
   box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.02),
      0px 1px 2px 0px rgba(0, 0, 0, 0.02), 1px 3px 3px 0px rgba(0, 0, 0, 0.02),
      1px 8px 5px 0px rgba(0, 0, 0, 0.01), 2px 13px 5px 0px rgba(0, 0, 0, 0),
      4px 21px 6px 0px rgba(0, 0, 0, 0);
   @include custom-scrollbar(true);

   svg {
      width: 1.25em;
      height: 1.16125em;
   }

   &.open {
      display: block;
   }
}

.originChoices {
   a {
      color: $va-grey-dark2;
      display: block;
      padding: 14px 24px;
      text-decoration: none;
      font-size: 0.875em;
      text-align: left;
      border-bottom: 1px solid $va-grey-light;
      transition: background-color 0.25s;

      &:hover {
         background-color: #f9f9f9;
      }

      &:active,
      &:focus {
         background-color: $va-grey-light;
      }
   }

   span {
      display: block;
   }
}
</style>
