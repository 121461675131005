<template>
   <div class="flightSpecialWrapper">
      <div class="flightSpecial" :class="{ 'is-business-special': isBusiness }">
         <div class="specialWrapper">
            <div
               class="specialContentWrapper"
               v-if="fare.image"
               :style="fareImage"
            >
               <div class="fareEnds">
                  <span>Sale ends {{ fareEndDate }}</span>
               </div>
               <div class="specialFareInfoWrapper">
                  <div class="specialFareInfo">
                     <h3 class="specialDestination">
                        {{ fare.destination_display }}
                     </h3>
                     <p class="specialFareClass">
                        {{ fare.cabin }} {{ fare.dir.toLowerCase() }}
                     </p>
                     <p class="specialPricingText">
                        {{ isBusiness ? "" : fareBrand + " fare" }} from
                     </p>
                  </div>
                  <div class="specialPriceCtaWrapper">
                     <span class="specialPricing">
                        ${{ fare.from_price }}*
                     </span>
                     <template>
                        <div
                           class="specialCta"
                           v-bind:class="{ filled: saleWithinWeek }"
                        >
                           <button
                              class="fareLinkOverlay"
                           >
                              <svg width="20" height="20">
                                 <use href="#arrow-right"></use>
                              </svg>
                           </button>
                        </div>
                     </template>
                  </div>
               </div>
            </div>
            <div v-else>
               <img class="fareImage" :src="fallbackImg" />
            </div>
         </div>
      </div>
      <div class="travelPeriod">
         <p class="specialTravelPeriods">{{ fareTravelPeriods }}</p>
      </div>
   </div>
</template>

<script>
//import Fare from "@/models/Fare";
import fallbackImgPath from "@/assets/images/iata-fallback.jpg";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Australia/Brisbane");

export default {
  inject: ["basePath", "activeTests"],
  name: "FlightSpecialCardOneClick",
  props: ["fare", "specialsDomain", "index", "visible", "count"],
  data() {
    return {
      // flightSpecialWrappersCount: 0,
      screenSize: this.getScreenSize()
    };
  },
  mounted() {
    // this.flightSpecialWrappersCount = this.getFlightSpecialWrapperCount();
    // Add resize event listener
    window.addEventListener("resize", this.updateScreenSize);
  },
  beforeDestroy() {
    // Remove resize event listener
    window.removeEventListener("resize", this.updateScreenSize);
  },
  methods: {
    // getFlightSpecialWrapperCount() {
    //   const container = document.querySelector(".flight-specials-container");
    //   if (container) {
    //     return container.querySelectorAll(".flightSpecialWrapper").length;
    //   }
    //   return 0; // Return a default value if container is not found
    // },
    getScreenSize() {
      const width = window.innerWidth;
      if (width <= 728) {
        return "mob";
      } else if (width <= 960) {
        return "tab";
      } else {
        return "desk";
      }
    },
    updateScreenSize() {
      this.screenSize = this.getScreenSize();
    }
  },
  computed: {
    fallbackImg() {
      if (this.basePath) {
        return (
          "//" +
          String(this.basePath)
            .replace(/^\/+/, "")
            .split("/")[0] +
          fallbackImgPath
        );
      }
      return fallbackImgPath;
    },
    fareEndDate: function () {
      let dateString = "unknown";
      let fare = this.fare;
      if (fare.travel_periods && fare.travel_periods[0]) {
        let jsDate = new Date();
        jsDate.setTime(fare.sale_end * 1000);
        let date = dayjs.tz(jsDate);
        dateString = date.format("DD MMM YYYY");
      }
      return dateString;
    },
    fareTravelPeriods: function () {
      let travel_period = this.fare.travel_periods[0];
      let jsDateStart = new Date(travel_period.start_date * 1000);
      let jsDateEnd = new Date(travel_period.end_date * 1000);

      let dateStart = dayjs.tz(jsDateStart);
      let dateEnd = dayjs.tz(jsDateEnd);

      let periodString =
        "For travel " +
        dateStart.format("DD MMM YYYY") +
        " to " +
        dateEnd.format("DD MMM YYYY");
      return periodString;
    },

    fareImage: function () {
      let imageURL = this.fare.image;
      // const count = this.count;
      // console.log(count);

      if (this.count <= 2) {
        if (this.screenSize === "mob") {
          imageURL = imageURL.replace(
            "flight_specials_thumbnail_rectangle",
            "flight_specials_thumbnail_portrait"
          );
        } else if (this.screenSize === "tab") {
          imageURL = imageURL.replace(
            "flight_specials_thumbnail_rectangle",
            "flight_specials_thumbnail_square"
          );
        } else {
          imageURL = imageURL.replace(
            "flight_specials_thumbnail_rectangle",
            "flight_specials_thumbnail_landscape"
          );
        }
      } else if (this.count === 3) {
        if (this.screenSize === "mob" || this.screenSize === "tab") {
          imageURL = imageURL.replace(
            "flight_specials_thumbnail_rectangle",
            "flight_specials_thumbnail_portrait"
          );
        } else {
          imageURL = imageURL.replace(
            "flight_specials_thumbnail_rectangle",
            "flight_specials_thumbnail_square"
          );
        }
      } else {
        imageURL = imageURL.replace(
          "flight_specials_thumbnail_rectangle",
          "flight_specials_thumbnail_portrait"
        );
      }

      return {
        "background-image":
          "linear-gradient(180deg, rgba(0, 0, 0, 0) 56.25%, rgba(0, 0, 0, 0.8) 88.39%), url(" +
          imageURL +
          ")",
      };
    },
    fareBrand: function () {
      // Only Economy fares have brands.
      if (this.fare.cabin.toLowerCase() !== "economy") return "";

      const travelPeriod = this.fare.travel_periods.length
        ? this.fare.travel_periods[0]
        : false;
      if (!travelPeriod) return "";
      const brand = travelPeriod.fare_brand;
      if (this.fare.cabin.toLowerCase() === brand.toLowerCase()) return "";

      return brand.charAt(0).toUpperCase() + brand.slice(1);
    },
    isBusiness: function () {
      return this.fare.cabin === "Business";
    },
    fareSaleLink: function () {
      if (this.isHPFlightSearch) {
        const origin = this.fare.origin;
        const dest = this.fare.destination;
        const saleStart = new Date(this.fare.sale_start * 1000);
        const monthYear =
          String(1 + saleStart.getMonth()).padStart(2, "0") +
          "-" +
          saleStart.getFullYear();
        return `https://www.virginaustralia.com/#/book-trip?bookingType=flights&origin=${origin}&destination=${dest}&trip_type=0&selectedMonth=${monthYear}&showSaleFare=1`;
      }
      let href = this.specialsDomain + this.fare.url;
      if (this.fare.url && this.fare.url.includes("//")) {
        href = this.fare.url;
      }
      const cmpParams = "?icmpid=va%7caem%7caem_hp%7csp_bubble%7cmvt1%7cna";
      return href + cmpParams;
    },
    saleWithinWeek: function () {
      let oneweek = 604800 * 1000; // 1 week in milliseconds
      let saleEnd = this.fare.sale_end * 1000;
      let now = new Date().getTime();
      return now + oneweek >= saleEnd;
    }
  },
};
</script>

<style lang="scss">
@import "../styles/variables";

// Scope all CSS to prevent leaking into the parent site
.va-embedded-flight-specials.va-embedded-flight-specials .flight-specials {

  .flightSpecial {
    position: relative;
    display: flex;
    min-height: 20em;
    max-height: 20em;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-self: stretch;
    border-radius: 0.5em;
    background: $white;
    overflow: hidden;
    transition: border 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  }

  .fareImage {
    max-width: 100%;
    background: transparent;
    height: 7.5em;
    object-fit: cover;
    pointer-events: none;
    width: 100%;
  }

  .specialContentWrapper {
    display: flex;
    padding: 1em 1em 1em 1.5em;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
  }

  .specialFareInfoWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin-top: auto;

    .specialFareInfo {

      .specialDestination,
      .specialPricingText {
        padding-bottom: 4px;
      }
    }

    .specialPriceCtaWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
    }
  }

  h3.specialDestination {
    color: $white;
    font-size: 1.3125em;
    font-weight: 500;
    line-height: 26px;
    margin: 0;
    font-family: $font-heading;

    @include bp("small") {
      font-size: 1.125em;
    }

    &.specials-empty-title {
      background: unset;

      &:hover {
        background: unset;
        color: $va-purple-dark;
      }
    }
  }

  .specialFareClass,
  p.specialPricingText {
    color: $white;
    font-size: 0.75em;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    text-decoration: none;
  }

  .specialPricing {
    color: $white;
    font-family: $font-heading;
    font-weight: 500;
    font-size: 27px;
    line-height: 34px;
  }

  .specialCta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-self: stretch;

    button.fareLinkOverlay.fareLinkOverlay {
      padding: 10px;
      background-color: transparent;
      border-radius: 50%;
      width: auto;
      border: 0;
      cursor: pointer;
      transition: background-color 0.25s ease-in-out;
      border: 1px solid transparent;
      display: flex;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      &:after {
        content: none;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }

      &:focus-visible,
      &:focus {
        background-color: rgba(255, 255, 255, 0.3);
        border: 1px solid $white;
        outline: none;
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .specialTravelPeriods {
    color: $va-grey-med;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    padding-top: 8px;
  }

  .fareEnds {
    background-color: $white;
    padding: 0.25em 1em;
    border-radius: 2em;
    display: flex;

    span {
      color: $va-red;
      font-size: 10px;
      line-height: 13px;
      font-weight: 500;
      font-family: $font-heading;
    }
  }
}
</style>
