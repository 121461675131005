<template>
  <!-- Enable 1 click or regular link to specials for each type of card depending on the activated tests -->
  <div class="card-parent" :class="{ 'standard-cards': !isHPImageCards, 'image-cards': isHPImageCards }" v-if="isOneClickTest" @click="$emit('show-oneclick-dialog', fare)">
    <FlightSpecialCardOneClick v-if="isHPImageCards" :count="count" v-bind:fare="fare" v-bind:specialsDomain="specialsDomain"></FlightSpecialCardOneClick>
    <FlightSpecialCard v-else v-bind:fare="fare" v-bind:specialsDomain="specialsDomain"></FlightSpecialCard>
  </div>
  <a class="card-parent" :class="{ 'standard-cards': !isHPImageCards, 'image-cards': isHPImageCards }" v-else :href="fareSaleLink">
    <FlightSpecialCardOneClick v-if="isHPImageCards" v-bind:fare="fare" v-bind:specialsDomain="specialsDomain"></FlightSpecialCardOneClick>
    <FlightSpecialCard v-else v-bind:fare="fare" v-bind:specialsDomain="specialsDomain"></FlightSpecialCard>
  </a>
</template>

<script>
  import FlightSpecialCard from './FlightSpecialCard.vue';
  import FlightSpecialCardOneClick from './FlightSpecialCardOneClick.vue';

  export default {
    inject: ["basePath", "activeTests"],
    name: "CardParent",
    props: ["fare", "specialsDomain", "index", "count"],
    components: {
      FlightSpecialCard,
      FlightSpecialCardOneClick
    },
    computed: {
      isOneClickTest: function () {
        return this.activeTests.value?.includes('hp_flight_one_click');
      },
      isHPImageCards() {
        return this.activeTests.value?.includes('hp_image_cards');
      },
      isHPFlightSearch() {
        return this.activeTests.value?.includes('hp_flight_search');
      },
      isHPFlightSearchFareDates() {
        return this.activeTests.value?.includes('hp_flight_search_fare_dates');
      },
      fareSaleLink: function () {
        if (this.isHPFlightSearch || this.isHPFlightSearchFareDates) {
          const origin = this.fare.origin;
          const dest = this.fare.destination;
          const fareDates = this.fareTravelPeriodDates;
          const monthYear = fareDates.start.format('MM-YYYY');
          const tripType = this.fare.dir == 'Return' ? 0 : 1;
          // Params that apply to both tests
          let deepLink = `https://www.virginaustralia.com/#/book-trip?bookingType=flights&origin=${origin}&destination=${dest}&trip_type=${tripType}`;
          // Params only for the test where the fare period is passed in
          if (this.isHPFlightSearchFareDates) {
            const fareStartDate = fareDates.start.format('DD-MM-YYYY');
            const fareEndDate = fareDates.end.format('DD-MM-YYYY');
            deepLink += `&saleStartDate=${fareStartDate}&saleEndDate=${fareEndDate}&showLowFare=0`;
          }
          // Params for only the main test
          else {
            deepLink += `&selectedMonth=${monthYear}&showSaleFare=1&icmpid=va:dig:flights_to:fo1:na:Oct23`;
          }
          return deepLink;
        }
        let href = this.specialsDomain + this.fare.url;
        if (this.fare.url && this.fare.url.includes("//")) {
          href = this.fare.url;
        }
        const cmpParams = "?icmpid=va:aem:aem_hp:sp_bubble:mvt1:na";
        return href + cmpParams + "#" + this.fare.key;
      },
    },
    methods: {
      handleCardClick() {
        if (this.isOneClickTest) {
          this.showDialog = true;
        }
      }
    }
  }
</script>

<style scoped lang="scss">
@import "../styles/variables";

.card-parent {
  cursor: pointer;
}

a.card-parent {
  text-decoration: none;
}

.image-cards {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.5em;

  &:hover {
    .specialCta button.fareLinkOverlay.fareLinkOverlay {
      background-color: rgba(225, 10, 10, 0.15) !important;
    }
  }

  &:focus-visible,
  &:focus {
    .specialCta button.fareLinkOverlay.fareLinkOverlay {
      background-color: rgba(225, 10, 10, 0.15) !important;
      outline: none;
    }
  }

  &:active {
    .specialCta button.fareLinkOverlay.fareLinkOverlay {
      background-color: rgba(225, 10, 10, 0.15) !important;
    }
  }
}

.standard-cards {
  flex: 0 0 254px;
  display: flex;
}
</style>

<style lang="scss">
@import "../styles/variables";

.image-cards {
  .specialContentWrapper {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 20em; //320px
    width: auto;
    min-width: 15.75em; //252px
  }
}
</style>
