<template>
   <div class="flight-special" :class="{'is-business-special': isBusiness}">
         <div class="special-image-wrapper">
            <div v-if="fare.image" v-html="fareImage"></div>
            <div v-else>
               <img
                  class="fare-image"
                  :src="fallbackImg"
               />
            </div>
         </div>
         <div class="special-content-wrapper">
            <div class="special-fare-info">
               <h3 class="special-destination">
                  {{ fare.destination_display }}
               </h3>
               <p class="special-fare-class">
                  {{ fare.cabin }} {{ fare.dir.toLowerCase() }}
               </p>
               <p class="special-pricing-text">{{ isBusiness ? 'Business' : fareBrand }} fare from</p>
               <span class="special-pricing">
                  <span class="price-currency">$</span>{{ fare.from_price }}<span class="suffix">*</span>
               </span>
            </div>
            <div class="special-cta-wrapper">
               <p class="special-travel-periods">{{ fareTravelPeriods }}</p>
               <div
                  class="special-cta"
                  v-bind:class="{ filled: saleWithinWeek }"
               >
                  <span class="fare-link-overlay">
                     <template v-if="!isHPFlightSearch && !isHPFlightSearchFareDates">
                        Book by {{ fareEndDate }}
                     </template>
                     <template v-else>See dates and book</template>
                  </span>
               </div>
            </div>
         </div>
         <div class="special-content-footer" v-if="isHPFlightSearch || isHPFlightSearchFareDates">
            <svg class="clock-icon" width="16" height="16">
               <use href="#clock"></use>
            </svg>
            <span class="special-footer-text">Sale ends {{  fareEndDate }}</span>
         </div>
   </div>
</template>

<style scoped lang="scss">
@import "../styles/variables";

.special-content-footer {
   background-color: $va-grey-light2;
   text-align: center;
   padding: .5em;
   .clock-icon {
      fill: $va-red;
      width: 16px;
      height: 16px;
      margin-right: .25em;
      vertical-align: middle;
   }
   .special-footer-text {
      color: $va-red;
      font-size: 11px;
      line-height: 13px;
      font-weight: 500;
      vertical-align: middle;
   }
}

</style>

<script>
//import Fare from "@/models/Fare";
import fallbackImgPath from '@/assets/images/iata-fallback.jpg';

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Australia/Brisbane");

export default {
   inject: ['basePath', 'activeTests'],
   name: "FlightSpecialCard",
   props: ["fare", "specialsDomain", "index"],
   computed: {
      fallbackImg() {
         if (this.basePath) {
            return '//'+ String(this.basePath).replace(/^\/+/, '').split('/')[0] + fallbackImgPath;
         }
         return fallbackImgPath;
      },
      fareEndDate: function() {
         let dateString = "unknown";
         let fare = this.fare;
         if (fare.travel_periods && fare.travel_periods[0]) {
            let jsDate = new Date();
            jsDate.setTime(fare.sale_end * 1000);
            let date = dayjs.tz(jsDate);
            dateString = date.format("DD MMM YYYY");
         }
         return dateString;
      },
      fareTravelPeriodDates: function() {
         let travel_period = this.fare.travel_periods[0];
         let jsDateStart = new Date(travel_period.start_date * 1000);
         let jsDateEnd = new Date(travel_period.end_date * 1000);

         let dateStart = dayjs.tz(jsDateStart);
         let dateEnd = dayjs.tz(jsDateEnd);
         return {
            start: dateStart,
            end: dateEnd,
         }
      },
      fareTravelPeriods: function() {
         const dates = this.fareTravelPeriodDates;

         let periodString =
            "For travel " +
            dates.start.format("DD MMM YYYY") +
            " to " +
            dates.end.format("DD MMM YYYY");
         return periodString;
      },
      fareImage: function() {
         if (this.fare.image) {
            return '<img class="fare-image" src="' + this.fare.image + '" />';
         }
         return "";
      },
      fareBrand: function() {
         // Only Economy fares have brands.
         if (this.fare.cabin.toLowerCase() !== "economy") return "";

         const travelPeriod = this.fare.travel_periods.length
            ? this.fare.travel_periods[0]
            : false;
         if (!travelPeriod) return "";
         const brand = travelPeriod.fare_brand;
         if (this.fare.cabin.toLowerCase() === brand.toLowerCase()) return "";

         return brand.charAt(0).toUpperCase() + brand.slice(1);
      },
      isBusiness: function() {
         return this.fare.cabin === 'Business';
      },
      saleWithinWeek: function() {
         let oneweek = 604800 * 1000; // 1 week in milliseconds
         let saleEnd = this.fare.sale_end * 1000;
         let now = new Date().getTime();
         return now + oneweek >= saleEnd;
      },
      isHPFlightSearch() {
         return this.activeTests.value?.includes('hp_flight_search');
      },
      isHPFlightSearchFareDates() {
         return this.activeTests.value?.includes('hp_flight_search_fare_dates');
      }
   },
};
</script>

<style lang="scss">
@import "../styles/variables";

// Scope all CSS to prevent leaking into the parent site
.va-embedded-flight-specials.va-embedded-flight-specials .flight-specials {
   .specials-empty,
   .flight-special {
      position: relative;
      display: flex;
      max-width: 15.875em; //add 2px for borders
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-self: stretch;
      border-radius: 0.5em;
      border: 1px solid $va-grey-light;
      background: $white;
      overflow: hidden;
      transition: border .25s ease-in-out, box-shadow .25s ease-in-out;
      &:hover {
         border: 1px solid #DEA6C2;
         a.fare-link-overlay {
            color: #9A0000 !important;
            border-color: #9A0000 !important;
         }
         h3.specials-empty-title {
            color: $va-purple-dark;
         }
      }
      &:focus-visible,
      &:focus-within {
         border: 1px solid $va-red-hover;
         box-shadow: 0px 0px 0px 4px $va-redopaque-hover;
      }
      &.specials-empty {
         max-width: 15.875em;
         text-align: left;
      }


      &.is-business-special {
         &:hover {
            border: 1px solid $va-purpleopaque-hover;
            a.fare-link-overlay {
               color: $va-purple-dark !important;
               border-color: $va-purple-dark !important;
            }
         }
         &:focus-visible,
         &:focus-within {
            border: 1px solid $va-purple-hover;
            box-shadow: 0px 0px 0px 4px $va-purpleopaque-hover;
         }
         .special-pricing-text,
         .special-pricing,
         .price-currency {
            color: $va-purple;
         }
         .special-cta a.fare-link-overlay.fare-link-overlay {
            color: $va-purple;
            border-color: $va-purple;
            &:hover {
               color: $va-purple !important;
               border-color: $va-purple !important;
               background: $va-purpleopaque-hover;
            }
            &:focus-visible,
            &:focus {
               box-shadow: 0px 0px 0px 4px $va-purpleopaque-hover;
            }
            &:active {
               background: $va-purpleopaque-active !important;
               box-shadow: none;
            }
         }
      }
   }

   .no-specials-wrapper {
      flex-basis: auto;
      margin: 0 auto;
      border-radius: 8px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 1em 3em 0 1.5em;
      flex-direction: column;
      gap: 8px;
      background-color: #F9F9F9;
      .no-specials {
         display: flex;
         gap: 32px;
         .no-specials-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
         }
         h3 {
            color: $va-purple-dark;
            margin: 0 0 0.25em 0;
         }
      }
   }

   .special-image-wrapper {
      position: relative;
      width: 100%;

      div {
         height: 7.5em;
      }
   }

   .fare-image {
      max-width: 100%;
      background: transparent;
      height: 7.5em;
      object-fit: cover;
      pointer-events: none;
      width: 100%;
   }

   .special-content-wrapper,
   .special-empty-wrapper {
      display: flex;
      padding: 1em 1.5em 1.5em 1.5em;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      gap: 0.5em;
      height: 100%;
   }

   .special-empty-info {
      display: flex;
      flex-direction: column;
      padding-top: 0;
      width: 100%;
      height: 100%;
   }

   h3.special-destination,
   h3.specials-empty-title {
      color: $va-purple-dark;
      font-size: 1.125em;
      font-weight: 800;
      line-height: 24px;
      margin: 0;
      padding-bottom: 4px;
      @include bp("tab") {
         font-size: 1.3em;
      }
      @include bp("small") {
         font-size: 1.3em;
      }
      &.specials-empty-title {
         background: unset;
         &:hover {
            background: unset;
            color: $va-purple-dark;
         }
      }
   }

   .special-fare-class,
   .specials-empty-signup {
      color: $va-grey-dark2;
      font-size: 0.875em;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      padding-bottom: 8px;
      text-decoration: none;
   }

   p.special-pricing-text {
      color: $va-red;
      font-size: 0.875em;
      font-weight: 400;
      line-height: 1.25em;
      padding: 0;
      margin: 0;
   }

   .special-pricing {
      color: $va-red;
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
      .price-currency {
         color: $va-red;
         font-size: 24px;
         font-weight: 800;
         vertical-align: text-top;
      }
      .suffix {
         font-size: 18px;
         font-weight: 600;
         line-height: 23px;
         vertical-align: text-top;
      }
   }

   .special-book-by {
      position: relative;
      color: #fff;
      font-size: 0.6875em;
      text-align: right;
      max-width: 13em;
      margin: 0.5em auto 0 auto;
      height: 2.1em;
      border-radius: 0.33em;
      @include bp("small") {
         margin-left: 0;
      }
   }

   .special-empty-cta,
   .special-cta {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      .fare-link-overlay.fare-link-overlay {
         color: $va-red;
         font-size: 0.875em;
         font-weight: 800;
         line-height: 24px;
         text-decoration: none;
         padding: 8px 24px;
         border-radius: 0.25em;
         border: 1px solid $va-red;
         background-color: $white;
         width: 100%;
         white-space: nowrap;
         transition: background-color .25s ease-in-out, box-shadow .25s ease-in-out;
         &:after {
            content: none;
         }
         &:hover {
            color: $va-red !important;
            border: 1px solid $va-red !important;
            background-color: $va-redopaque-hover;
         }
         &:focus-visible,
         &:focus {
            box-shadow: 0px 0px 0px 4px $va-redopaque-hover;
            background-color: $white;
            outline: none;
         }
         &:active {
            background-color: $va-redopaque-active;
            box-shadow: none;
         }
      }
      &.special-empty-cta {
         margin-top: auto;
         width: 100%;
         a {
            width: 100%;
            text-align: center;
         }
      }
   }

   .special-travel-periods {
      color: $va-grey-med;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      padding-bottom: 8px;
   }
}
</style>
