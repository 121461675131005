<template>
   <transition name="dialog-fade">
      <div class="dialogOverlay" @click.self="close()">
         <iframe :src="iframeSrc" class="bookingForm"></iframe>
      </div>
   </transition>
</template>

<script>
export default {
   name: "Dialog",
   props: {
      visible: {
         type: Boolean,
         default: false,
      },
      title: {
         type: String,
         default: "Dialog",
      },
      fare: {
         type: Object,
         default: null,
      },
      bookingForm: {
         type: String,
         required: true,
      },
   },
   methods: {
      close() {
         this.$emit("close");
      },
      formatDate(ts) {
         const jsTs = ts * 1000;
         const date = new Date(jsTs);
         let day = date.getDate();
         let month = date.getMonth() + 1;
         const year = date.getFullYear();

         if (day < 10) {
            day = "0" + day.toString();
         }

         if (month < 10) {
            month = "0" + month.toString();
         }

         return year.toString() + "-" + month.toString() + "-" + day.toString();
      },
      isReturn(fare) {
         if (fare.dir === "Return") {
            return true;
         } else {
            return false;
         }
      },
      fareClass(fare) {
         let fc = "";
         switch (fare.cabin) {
            case "Business":
               fc = "B";
               break;
            default:
               fc = "E";
               break;
         }
         return fc;
      },
      fareInfo(fare, travelPeriod) {
         let _fare_brand =
            travelPeriod.fare_brand === null
               ? "Business"
               : travelPeriod.fare_brand;
         return `${this.capitalizeFirstLetter(
            _fare_brand
         )} fare, ${`${fare.cabin} ${fare.dir} from $${travelPeriod.from_price}`
            .toString()
            .toLocaleLowerCase()}`;
      },
      capitalizeFirstLetter(str) {
         return str.charAt(0).toUpperCase() + str.slice(1);
      },
   },
   computed: {
      iframeSrc() {
         const fare = this.fare;
         const travelPeriod = this.fare.travel_periods[0];

         const departDate = this.formatDate(travelPeriod.start_date);
         const returnDate = this.formatDate(travelPeriod.end_date);

         const params = {
            style: "oneclick",
            fare_info: this.fareInfo(fare, travelPeriod),
            o_iata: fare.origin,
            d_iata: fare.destination,
            dd: departDate,
            rd: returnDate,
            return: this.isReturn(fare),
            trip_class: this.fareClass(fare),
         };

         if (fare.promoCode) {
            params.promoCode = encodeURIComponent(fare.promoCode);
         }

         const qs = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");

         return encodeURI(`${this.bookingForm}?${qs}`);
      },
   },
   mounted() {
      // This function is called from within the iframe. Emit an event allowing the
      // window scoped function to interact with the component.
      window.addEventListener('message', event => {
         if (event.data == "closeBookingForm") {
            this.close();
         }
      },false);
   },
};
</script>

<style lang="scss">
@import "../styles/variables";

.dialogOverlay {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
}

.dialog-fade-enter-active,
.dialog-fade-leave-active {
   transition: opacity 0.25s ease-in-out;
}

.dialog-fade-enter,
.dialog-fade-leave-to {
   opacity: 0;
   transform: scale(0.9);
}

.bookingForm {
   max-width: 60.5em; // 968px
   width: 100%;
   max-height: calc(748px + 134px);
   height: 100%;
   border-radius: 1em;
   border: 0;

   @include bp("small") {
      max-height: 100vh;
   }

   @include bp("tab") {
      max-height: 100vh;
   }
}
</style>
