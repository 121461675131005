<template>
   <div class="flightSpecialWrapper flightSpecialWrapperLoading">
      <div class="flightSpecial">
         <div class="specialContentWrapper">
            <div class="specialFareInfoWrapper">
               <div
                  v-for="(lineWidth, i) in loadingLines"
                  :key="i"
                  class="specialLoadingLine"
                  :style="{ width: lineWidth + 'em' }"
               ></div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "FlightSpecialCardLoadingOneClick",
   data: () => ({
      // Info for loading cards
      // each array item represents one pulsing line
      // the number indicates the width in EM
      loadingLines: [9.5, 10.5, 9, 7.75, 3.5, 2.5],
   }),
};
</script>

<style lang="scss">
@import "../styles/variables";

// Scope all CSS to prevent leaking into the parent site
.va-embedded-flight-specials.va-embedded-flight-specials
   .flightSpecialWrapperLoading {
   // Many styles are inherited from main card component
   flex: 0 0 254px;

   .specialContentWrapper {
      background: rgba(0, 0, 0, 0.025);
   }

   .specialFareInfoWrapper {
      gap: 0.5em;
      margin-top: 0;
   }

   .specialLoadingLine {
      animation: vaSpecialCardLoading;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
   }

   .specialLoadingLine {
      height: 1rem;
      border-radius: 0.25rem;
      &:first-of-type {
         height: 1.25em;
         border-radius: 25px;
      }
      &:nth-of-type(2) {
         height: 1.63em;
         margin-top: 8.5em;
      }
      &:nth-of-type(3),
      &:nth-of-type(4) {
         height: 1em;
      }

      &:nth-of-type(5) {
         height: 2.13em;
         margin-top: 0.25em;
      }

      &:last-of-type {
         height: 2.5em;
         position: absolute;
         right: 1em;
         bottom: 1em;
         border-radius: 50%;
      }
   }
}

@keyframes vaSpecialCardLoading {
   from {
      background: #f9f9f9;
   }
   to {
      background: #e4e4e4;
   }
}
</style>
