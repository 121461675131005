<template>
  <div id="origin-select-wrapper">
    <div v-if="!hideFilter" v-on-clickaway="closeOriginSelect">
      <h4>
        <span v-html="getTitle()"></span>
        <a
          class="origin-select-link"
          ref="originSelectLink"
          @click="openOriginSelect"
          tabindex="0"
        >
          <select
            ref="nativeInput"
            id="origin-selector-native"
            @change="originChangedSelect"
          >
            <option
              v-for="origin in flightOrigins"
              v-bind:key="origin.iata"
              :value="origin.iata"
            >
              {{ origin.name }}
            </option>
          </select>
          <svg class="location-marker-title">
            <use href="#location-picker"></use>
          </svg>
          {{ chosenOriginName }}
          <svg class="chevron-down" width="24" height="24">
            <use href="#chevron-down"></use>
          </svg>
        </a>
      </h4>
      <div id="origin-selector" ref="originSelector" :class="{ open: isOpen }">
        <div class="origin-choices">
          <span v-for="origin in flightOrigins" v-bind:key="origin.iata">
            <a
              href="#"
              :data-name="origin.name"
              :data-iata="origin.iata"
              @click="originChanged"
            >
              {{ origin.name }} ({{ origin.iata }})
            </a>
          </span>
        </div>
      </div>
    </div>
    <div v-else>
      <h4 v-html="getTitle()"></h4>
    </div>
  </div>
</template>

<script>
  import { mixin as clickaway } from 'vue-clickaway';

  export default {
    mixins: [clickaway],
    name: 'OriginSelector',
    props: ['chosenOrigin', 'chosenOriginName', 'flightOrigins', 'title', 'hideFilter'],
    data: () => ({
      currentOrigin: '',
      currentOriginName: '',
      isOpen: false,
      resizeTimeout: null,
    }),
    methods: {
      getTitle() {
        if (this.title === '') {
          return 'Flight specials from ';
        } else {
          return this.title;
        }
      },
      originChanged(event) {
        event.preventDefault();
        var link = event.target;
        this.currentOrigin = link.dataset.iata;
        this.currentOriginName = link.dataset.name;
        this.$emit('updateOrigin', this.currentOrigin, this.currentOriginName);
        this.closeOriginSelect();
      },
      originChangedSelect(event) {
        var select = event.srcElement;
        var selectedOption = select.options[select.options.selectedIndex];

        this.currentOrigin = select.value;
        this.currentOriginName = selectedOption.text;

        this.$emit('updateOrigin', this.currentOrigin, this.currentOriginName);
      },
      openOriginSelect() {
        // Close the origin select if it's already open
        if (this.isOpen) {
          this.closeOriginSelect();
        } else {
          // if native input is hidden (display=none)
          if (!this.$refs.nativeInput.offsetWidth) {
            this.openMainOriginSelect();
          }
        }
      },
      openMainOriginSelect() {
        this.isOpen = true;
      },
      closeOriginSelect() {
        this.isOpen = false;
      },
      positionOriginSelector() {
        const originSelectLink = this.$refs.originSelectLink;
        const originSelector = this.$refs.originSelector;

        if (originSelectLink && originSelector) {
          const linkRectLeft = originSelectLink.offsetLeft;

          // Set the position of origin-selector
          originSelector.style.left = `${linkRectLeft}px`;
        }
      },
      debounce(func, wait) {
        let timeout;
        return function () {
          const context = this;
          const args = arguments;
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(context, args), wait);
        };
      },
    },
    mounted() {
      this.positionOriginSelector();
      this.debouncedResizeListener = this.debounce(this.positionOriginSelector, 200);
      window.addEventListener('resize', this.debouncedResizeListener);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.debouncedResizeListener);
    },
  };
</script>

<style lang="scss">
  @import '../styles/variables';

  #origin-select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: left;
    margin-bottom: 2.25em;
    @include bp(desk) {
      text-align: center;
    }
    @include bp(tab) {
      text-align: center;
    }

    > div {
      display: inline-flex;
      position: relative;
    }
    h4 {
      color: $va-purple-dark;
      font-weight: 800;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.68em;
      line-height: 29px;
      display: flex;
      align-items: center;
      row-gap: 4px;
      column-gap: 16px;
      flex-wrap: wrap;
      // @include bp(desk) {
      //   gap: 16px;
      // }
    }
  }

  .location-marker-title {
    fill: $va-red;
    width: .8em;
    height: .8em;
    margin-right: .25em;
    vertical-align: middle;
  }

  .origin-select-link {
    padding-right: .25em;
    padding-bottom: .1em;
    cursor: pointer;
    position: relative;
    display: inline-block; /* to allow native select 100% width */
    transition: background .3s, border-color .3s, box-shadow .3s;
    border-radius: 4px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: #fff;
    color: $va-purple-dark;
    border: 1px solid $va-grey-light;
    padding: 0.5em 1em;
    text-decoration: none;
    &:hover {
      border-color: #BFBFC0;
    }
    &:active {
      border-color: #8E8D8F;
    }
    &:focus,
    &:focus-within {
      outline: none;
      border-color: #CD0C0C;
      box-shadow: 0px 0px 0px 4px #FEF3F3;
      svg.chevron-down {
        transform: rotate(180deg);
      }
    }
    svg.chevron-down {
      transform: rotate(0);
      transition: transform .3s ease-in-out;
    }
  }

  #origin-selector-native {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 100%;
    width: 100%;
    pointer-events: all;

    display: none;
    @media screen and (max-width: 66em) {
      display: block;
    }
  }

  #origin-selector {
    position: absolute;
    // left: rem(292px) !important;
    // top: rem(65px);
    left: 18.75em !important;
    top: 4em;
    display: none;
    z-index: 2;
    max-height: 14.125em;
    min-width: 18em;
    overflow-y: auto;
    border-radius: 4px;
    background: $white;
    border: 1px solid $va-grey-light;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 1px 3px 3px 0px rgba(0, 0, 0, 0.02), 1px 8px 5px 0px rgba(0, 0, 0, 0.01), 2px 13px 5px 0px rgba(0, 0, 0, 0.00), 4px 21px 6px 0px rgba(0, 0, 0, 0.00);
    @include custom-scrollbar(true);
    @include bp('tab'){
      left: rem(356px);
    }
    svg {
      width: 1.25em;
      height: 1.16125em;
    }
    &.open {
      display: block;
    }
  }

  .origin-closest {
    margin-bottom: 1.5em;
    a {
      color: $white;
    }
    svg {
      fill: $va-purple-light;
    }
  }

  .origin-choices {
    a {
      color: $va-grey-dark2;
      display: block;
      padding: 14px 24px;
      text-decoration: none;
      font-size: 0.875em;
      text-align: left;
      border-bottom: 1px solid $va-grey-light;
      transition: background-color .25s;
      &:hover {
        background-color: #F9F9F9;
      }
      &:active,
      &:focus {
        background-color: $va-grey-light;
      }
    }
    span {
      display: block;
    }
  }

  .origin-bold {
    font-weight: bold;
  }

</style>
