class Fare {
  baggage = null;
  beverage = null;
  cabin = '';
  category = '';
  connection = null;
  connection_display = null;
  destination = '';
  destination_display = '';
  dir = '';
  displayed_discount = null;
  entertain = null;
  food = null;
  from_price = null;
  id = null;
  image = null;
  origin = '';
  origin_display = '';
  price_sort = null;
  promo_code = null;
  //ref = {};
  refreshment = null;
  sale_end = null;
  sale_start = null;
  seat = null;
  tag = "";
  title = "";
  travel_periods = [];
  type = "";
  key = '';
  url = '';
  static fromObject(obj){
    const instance = new Fare();
    for (const prop of Object.keys(instance)) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        instance[prop] = obj[prop];
      }
    }
    return instance;
  }
}

export default Fare;
