<template>
    <div class="flight-special-wrapper flight-special-wrapper-loading">
        <div class="flight-special">
            <div class="special-image-wrapper">
                <div class="fare-image"></div>
            </div>
            <div class="special-content-wrapper">
                <div v-for="(lineWidth, i) in loadingLines" :key="i" class="special-loading-line" :style="{width: lineWidth + 'em'}"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FlightSpecialCardLoading",
        data: () => ({
            // Info for loading cards
            // each array item represents one pulsing line
            // the number indicates the width in EM
            loadingLines: [
                5.75,
                10.875,
                11.375,
                11.75,
                10.5625,
                8.4375,
                3.3125
            ]
        }),
    }
</script>

<style lang="scss">
    @import "../styles/variables";

    // Scope all CSS to prevent leaking into the parent site
    .va-embedded-flight-specials.va-embedded-flight-specials .flight-special-wrapper-loading {
        // Many styles are inherited from main card component
        flex: 0 0 254px;

        .fare-image,
        .special-loading-line {
            animation: vaSpecialCardLoading;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }

        .special-loading-line {
            height: 0.75rem;
            border-radius: 0.25rem;
            &:last-child {
                margin-top: 1rem;
            }
        }

    }

    @keyframes vaSpecialCardLoading {
        from {
            background: #f9f9f9;
        }
        to {
            background: #e4e4e4;
        }
    }

</style>
