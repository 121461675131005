<template>
  <div ref="embeddedContainer" :data-default-origin="defaultOrigin" v-bind:class = "(isHPImageCards) ? 'va-embedded-flight-specials oneClick' : 'va-embedded-flight-specials'">
    <OriginSelector v-if="!isHPImageCards" v-bind:flightOrigins="flightOrigins" v-bind:chosenOriginName="currentOriginName" v-bind:chosenOrigin="currentOrigin" v-bind:title="title" v-bind:hideFilter="hideFilter" v-on:updateOrigin="updateOrigin"></OriginSelector>
    <OriginSelectorOneClick v-if="isHPImageCards" v-bind:flightOrigins="flightOrigins" v-bind:chosenOriginName="currentOriginName" v-bind:chosenOrigin="currentOrigin" v-bind:title="title" v-bind:hideFilter="hideFilter" v-on:updateOrigin="updateOrigin"></OriginSelectorOneClick>
    <div class="flight-specials-container">
      <div class="flight-specials" ref="container" @mousedown="handleMouseDown">

        <template v-if="loading">
          <template v-if="isHPImageCards">
            <FlightSpecialCardLoadingOneClick v-for="i in 4" :key="i"></FlightSpecialCardLoadingOneClick>
          </template>
          <template v-else>
            <FlightSpecialCardLoading v-for="i in 4" :key="i"></FlightSpecialCardLoading>
          </template>
        </template>

        <template v-else>
          <CardParent v-for="fare in json" :key="fare.key" :count="json.length" v-bind:fare="fare" v-bind:specialsDomain="specialsDomain" @show-oneclick-dialog="showDialog"></CardParent>
        </template>

        <template v-if="isHPImageCards">
          <template v-if="json.length > 0 && json.length < 2">
            <div class="flightSpecialWrapper empty">
              <div class="specialWrapperEmpty">
                <div class="specialEmptyContentWrapper">
                  <img :src="bagEmptyImg" alt="Empty sales image"/>
                  <h3 class="specialsEmptyTitle">Looking for more deals?</h3>
                  <div class="specialsEmptySignup">Sign up for Virgin Australia V-mail sales alerts</div>
                </div>
                <div class="specialEmptyCTAWrapper">
                  <div class="specialCta">
                    <a class="fareLinkOverlay" :href="preferencesLink">
                      <svg width="20" height="20">
                        <use href="#arrow-right-red"></use>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <template v-if="json.length > 0 && json.length < 4">
            <div class="flight-special-wrapper">
              <div class="specials-empty">
                <div class="special-image-wrapper">
                  <div><img :src="emptyImg" alt="Empty sales image"/></div>
                </div>
                <div class="special-empty-wrapper">
                  <div class="special-empty-info">
                    <h3 class="specials-empty-title">Looking for more deals?</h3>
                    <div class="specials-empty-signup">Sign up for Virgin Australia V-mail sales alerts</div>
                    <div class="special-empty-cta">
                      <a class="fare-link-overlay" :href="preferencesLink">Sign up</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-if="!loading && json.length == 0">
          <div class="flight-special-wrapper no-specials-wrapper">

            <div class="no-specials">
              <div class="no-specials-image-wrapper">
                <div><img :src="noSpecialsImg" alt="No sales image"/></div>
              </div>
              <div class="no-specials-content">
                <h3 class="no-specials-title">No specials found</h3>
                <div class="specials-empty-signup">It looks like all of the specials from your airport have taken off.</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="all-specials-link-wrapper" v-if="!isHPImageCards">
      <a id="all-specials-link" :href="allSpecialsLink">See all specials</a>
    </div>

    <div class="allSpecialsLinkWrapper" v-if="isHPImageCards">
      <a id="allSpecialsLink" :href="allSpecialsLink">See all specials</a>
    </div>

    <Dialog
      v-if="dialogActive && this.isOneClickTest"
      @close="closeDialog"
      :fare="dialogFare"
      v-bind:bookingForm="bookingForm"
      ref="dialogComponent"
    ></Dialog>

    <div id="svg-resources" style="display: none">
      <svg xmlns="http://www.w3.org/2000/svg"><symbol id="price-tag-full" viewBox="0 0 148.03 22"><path d="M144.09 0H10.14c-.71.03-1.42.33-1.97.88L1.39 8.97c-1.87 1.87-1.85 2.39 0 4.24L8 21.12c.59.6 1.37.89 2.14.88h133.95c2.17 0 3.94-1.79 3.94-4V4c0-2.21-1.76-4-3.94-4zM10.63 14.53a3.53 3.53 0 11-.001-7.059 3.53 3.53 0 01.001 7.059z" fill="#d61e3e"/></symbol></svg>
      <svg xmlns="http://www.w3.org/2000/svg"><symbol id="price-tag-hollow" viewBox="0 0 149.03 23"><path d="M144.59.5L10.64.51h0c-.71.03-1.42.32-1.97.87L1.89 9.47c-1.87 1.87-1.85 2.39 0 4.24l6.61 7.91c.59.6 1.37.89 2.14.87h133.95c2.17 0 3.94-1.79 3.94-4v-14c0-2.2-1.76-3.99-3.94-3.99zM11.13 14.44c-1.62 0-2.94-1.32-2.94-2.94 0-1.62 1.32-2.94 2.94-2.94s2.94 1.32 2.94 2.94c0 1.62-1.31 2.94-2.94 2.94z" fill="none" stroke="#d61e3e" stroke-miterlimit="10"/></symbol></svg>
      <svg xmlns="http://www.w3.org/2000/svg"><symbol id="price-tag-icon" viewBox="0 0 61.047 61.047"><path d="M0,30.061V5.723A5.723,5.723,0,0,1,5.723,0H30.061a5.723,5.723,0,0,1,4.047,1.676L59.371,26.939a5.723,5.723,0,0,1,0,8.094L35.033,59.371a5.723,5.723,0,0,1-8.094,0L1.676,34.107A5.723,5.723,0,0,1,0,30.061ZM13.354,7.631a5.723,5.723,0,1,0,5.723,5.723A5.723,5.723,0,0,0,13.354,7.631Z"/></symbol></svg>
      <svg xmlns="http://www.w3.org/2000/svg"><symbol id="location-marker" viewBox="0 0 16.416 21.889"><path d="M154.269,892.4c-6.211-9-7.364-9.929-7.364-13.239a8.208,8.208,0,1,1,16.416,0c0,3.309-1.153,4.234-7.364,13.239a1.027,1.027,0,0,1-1.688,0Zm.844-9.819a3.42,3.42,0,1,0-3.42-3.42A3.42,3.42,0,0,0,155.113,882.58Z" transform="translate(-146.905 -870.951)"/></symbol></svg>
      <svg xmlns="http://www.w3.org/2000/svg" id="chevron-down" class="chevron-down" width="24" height="24" viewBox="0 0 24 24" fill="none"><mask id="mask0_1_840" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"> <rect width="24" height="24" fill="#D9D9D9"/></mask><g mask="url(#mask0_1_840)"><path d="M12 15.6999L6 9.69993L7.4 8.29993L12 12.8999L16.6 8.29993L18 9.69993L12 15.6999Z" fill="#1D1C1F"/></g></svg>
      <svg xmlns="http://www.w3.org/2000/svg" id="location-picker" width="25" height="24" viewBox="0 0 25 24" fill="none"><mask id="mask0_1_860" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24"><rect x="0.5" width="24" height="24" fill="#D9D9D9"/></mask><g mask="url(#mask0_1_860)"><path d="M12.5 19C10.15 17.2667 8.39567 15.5833 7.237 13.95C6.079 12.3167 5.5 10.7167 5.5 9.15C5.5 7.96667 5.71267 6.929 6.138 6.037C6.56267 5.14567 7.10833 4.4 7.775 3.8C8.44167 3.2 9.19167 2.75 10.025 2.45C10.8583 2.15 11.6833 2 12.5 2C13.3167 2 14.1417 2.15 14.975 2.45C15.8083 2.75 16.5583 3.2 17.225 3.8C17.8917 4.4 18.4377 5.14567 18.863 6.037C19.2877 6.929 19.5 7.96667 19.5 9.15C19.5 10.7167 18.9207 12.3167 17.762 13.95C16.604 15.5833 14.85 17.2667 12.5 19ZM12.5 11C13.05 11 13.521 10.804 13.913 10.412C14.3043 10.0207 14.5 9.55 14.5 9C14.5 8.45 14.3043 7.979 13.913 7.587C13.521 7.19567 13.05 7 12.5 7C11.95 7 11.4793 7.19567 11.088 7.587C10.696 7.979 10.5 8.45 10.5 9C10.5 9.55 10.696 10.0207 11.088 10.412C11.4793 10.804 11.95 11 12.5 11ZM5.5 22V20H19.5V22H5.5Z" fill="#E10A0A"/></g></svg>
      <svg xmlns="http://www.w3.org/2000/svg" id="clock" width="17" height="16" viewBox="0 0 17 16" fill="none"><mask id="mask0_284_873" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16"><rect x="0.5" width="16" height="16" fill="#D9D9D9"/></mask><g mask="url(#mask0_284_873)"><path d="M8.50016 14.6666C7.57794 14.6666 6.71127 14.4915 5.90016 14.1413C5.08905 13.7915 4.3835 13.3166 3.7835 12.7166C3.1835 12.1166 2.70861 11.4111 2.35883 10.6C2.00861 9.78887 1.8335 8.9222 1.8335 7.99998C1.8335 7.07776 2.00861 6.21109 2.35883 5.39998C2.70861 4.58887 3.1835 3.88331 3.7835 3.28331C4.3835 2.68331 5.08905 2.2082 5.90016 1.85798C6.71127 1.5082 7.57794 1.33331 8.50016 1.33331C9.42239 1.33331 10.2891 1.5082 11.1002 1.85798C11.9113 2.2082 12.6168 2.68331 13.2168 3.28331C13.8168 3.88331 14.2917 4.58887 14.6415 5.39998C14.9917 6.21109 15.1668 7.07776 15.1668 7.99998C15.1668 8.9222 14.9917 9.78887 14.6415 10.6C14.2917 11.4111 13.8168 12.1166 13.2168 12.7166C12.6168 13.3166 11.9113 13.7915 11.1002 14.1413C10.2891 14.4915 9.42239 14.6666 8.50016 14.6666ZM10.7002 11.1333L11.6335 10.2L9.16683 7.73331V4.66665H7.8335V8.26665L10.7002 11.1333Z" fill="#E10A0A"/></g><defs><linearGradient id="paint0_linear_284_873" x1="1.8335" y1="14.6666" x2="15.1668" y2="14.6666" gradientUnits="userSpaceOnUse"><stop stop-color="#E24C9B"/><stop offset="0.300018" stop-color="#E22B53"/><stop offset="1" stop-color="#E10A0A"/></linearGradient></defs></svg>
      <svg xmlns="http://www.w3.org/2000/svg" id="arrow-right" width="20" height="20" viewBox="0 0 20 20" fill="none"><mask id="mask0_2191_6416" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"><rect x="0.5" y="0.5" width="19" height="19" fill="#E4E4E4" stroke="#E4E4E4"/></mask><g mask="url(#mask0_2191_6416)"><path d="M3.33335 9.16683H13.4792L8.81252 4.50016L10 3.3335L16.6667 10.0002L10 16.6668L8.81252 15.5002L13.4792 10.8335H3.33335V9.16683Z" fill="white"/></g></svg>
      <svg xmlns="http://www.w3.org/2000/svg" id="arrow-right-red" width="20" height="20" viewBox="0 0 20 20" fill="none"><mask id="mask0_2304_7213" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"><rect x="0.5" y="0.5" width="19" height="19" fill="#E4E4E4" stroke="#E4E4E4"/></mask><g mask="url(#mask0_2304_7213)"><path d="M3.33317 9.16634H13.479L8.81234 4.49967L9.99984 3.33301L16.6665 9.99967L9.99984 16.6663L8.81234 15.4997L13.479 10.833H3.33317V9.16634Z" fill="#E10A0A"/></g></svg>
    </div>
  </div>
</template>

<script>
import CardParent from './components/CardParent.vue';
import FlightSpecialCardLoading from './components/FlightSpecialCardLoading.vue';
import Dialog from './components/Dialog.vue';

import OriginSelector from './components/OriginSelector';
import Fare from "@/models/Fare";

import emptyImgPath from '@/assets/images/sales-empty.svg';
import bagTrackingImgPath from '@/assets/images/bagTracking.svg';
import noSpecialsImgPath from '@/assets/images/no-specials-img.svg';

import OriginSelectorOneClick from './components/OriginSelectorOneClick.vue';
import FlightSpecialCardLoadingOneClick from './components/FlightSpecialCardLoadingOneClick.vue';

export default {
  inject: ['basePath', 'specialsUrl', 'activeTests'],
  props: {
    dataDefaultOrigin: {
      type: String,
      default() {
        return 'BNE';
      }
    },
    dataLimit: {
      type: String,
      default() {
        // return '5';
        return '4';
      }
    },
    dataSaleEvent: {
      type: String,
      default() {
        return '';
      }
    },
    dataTitle: {
      type: String,
      default() {
        return '';
      }
    },
    dataHideFilter: {
      type: String,
      default() {
        return '0';
      }
    },
    dataMinPrice: {
      type: String,
      default() {
        return '0';
      }
    },
    dataMaxPrice: {
      type: String,
      default() {
        return '0';
      }
    },
    dataExclude: {
      type: String,
      default() {
        return '';
      }
    },
  },
  components: {
    OriginSelector,
    CardParent,
    Dialog,
    FlightSpecialCardLoading,
    FlightSpecialCardLoadingOneClick,
    OriginSelectorOneClick,
  },
  data: () => ({
    json: [],
    loading: true,
    currentOrigin: '',
    currentOriginName: '',
    defaultOrigin: '',
    flightOrigins: {},
    saleEventName: '',
    // limit: 5,
    limit: 4,
    minPrice: 0,
    maxPrice: 0,
    hideFilter: false,
    title: '',
    isFirstRun: true,
    preferencesLink: 'https://specials.virginaustralia.com/vmail/sign_up?icmpid=va:aem:aem_hp:look_bubble:mvt1:na',
    isDragging: false,
    startX: 0,
    scrollLeft: 0,
    dialogActive: false,
    dialogFare: null,
    bookingForm: process.env.VUE_APP_SPECIALS_BOOKING_FORM_DOMAIN
  }),
  computed: {
    baseUrl() {
      if (this.basePath) {
        return '//' + String(this.basePath).replace(/^\/+/, '').split('/')[0];
      }
      return '';
    },
    noSpecialsImg() {
      return this.baseUrl + noSpecialsImgPath;

    },
    emptyImg() {
      return this.baseUrl + emptyImgPath;
    },
    bagEmptyImg() {
      return this.baseUrl + bagTrackingImgPath;
    },
    specialsDomain: function () {
      return this.specialsUrl ?? process.env.VUE_APP_SPECIALS_DOMAIN;
    },
    allSpecialsLink: function () {
      const cmpParams = '?icmpid=va:aem:aem_hp:safs_button:mvt1:na';
      return this.specialsDomain + '/au' + cmpParams;
    },
    isHPImageCards() {
      return this.activeTests.value?.includes('hp_image_cards');
    },
    get_utils_path() {
      if (process.env.NODE_ENV === 'development') {
        return process.env.VUE_APP_UTILS_PATH;
      } else {
        let specialsDomain = this.specialsDomain;
        return specialsDomain + process.env.VUE_APP_UTILS_PATH;
      }
    },
    isOneClickTest: function () {
      return this.activeTests.value?.includes('hp_flight_one_click');
    }
    /*currentOriginName: function() {
      var currentOriginName = 'Brisbane';
      if (this.flightOrigins.length) {
        var $this = this;
        this.flightOrigins.forEach(function(origin) {
          if (origin.iata == $this.currentOrigin) {
            currentOriginName = origin.name;
          }
        });
      }
      return currentOriginName;
    }*/
  },
  methods: {
    handleMouseDown(event) {
      // if (!this.isTextElement(event.target)) {
      if (!this.isScrollbar(event.target) && !this.isTextElement(event.target)) {
        this.isDragging = true;
        this.startX = event.clientX;
        this.scrollLeft = this.$refs.container.scrollLeft;
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
        this.$refs.container.style.cursor = 'grabbing';
        this.$refs.container.classList.add('no-select');
      }
    },
    handleMouseMove(event) {
      if (!this.isDragging) return;
      const x = event.clientX - this.startX;
      this.$refs.container.scrollLeft = this.scrollLeft - x;
    },
    handleMouseUp(event) {
      if (this.isDragging) {
        this.isDragging = false;
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
        this.$refs.container.style.cursor = 'auto';
        this.$refs.container.classList.remove('no-select');

        if (this.$refs.container.scrollLeft == (event.clientX - this.startX)) {
          this.clearSelection();
        }
      }
    },
    isTextElement(element) {
      return (
        element.tagName === 'H3' ||
        element.tagName === 'P' ||
        element.tagName === 'SPAN'
      );
    },
    isScrollbar(element) {
      return element.classList.contains('flight-specials') && element.offsetWidth < element.scrollWidth;
    },
    clearSelection() {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
      if (document.selection) {
        document.selection.empty();
      }
    },
    getExcludeOrigins() {
      let excludeOrigins = [];
      if (this.dataExclude !== '') {
        // Strip whitespace
        const excludeString = this.dataExclude.replace(/\s/g, '').toUpperCase();
        const excludePairs = excludeString.split(',');
        const re = new RegExp(/^[A-Z*]{1,3}-[A-Z*]{1,3}$/);
        excludePairs.forEach((excludePair) => {
          if (re.test(excludePair)) {
            const ports = excludePair.split('-');
            // If the destination is a wildcard, no fares will exist so no point
            // showing the origin.
            if (ports[1] === '*') {
              excludeOrigins.push(ports[0]);
            }
          }
        });
      }
      return excludeOrigins;
    },
    // Method to fetch JSON fares
    fetchJson() {

      let utils_path = this.basePath ?? this.get_utils_path();

      let feed_params = 'origin=' + this.currentOrigin;
      feed_params += '&limit=' + this.dataLimit;
      feed_params += '&deduplication=on';
      feed_params += '&filter_inactive_sales=on';
      if (this.saleEventName !== '') {
        feed_params += '&sale_name=' + this.dataSaleEvent;
      }

      if (this.dataMinPrice > 0) {
        feed_params += '&price_from=' + this.dataMinPrice;
      }

      if (this.dataMaxPrice > 0) {
        feed_params += '&price_to=' + this.dataMaxPrice;
      }

      if (this.dataExclude !== '') {
        feed_params += '&exclude=' + this.dataExclude;
      }

      fetch(utils_path + 'json.php?' + feed_params)
        .then(response => response.json())
        .then(json => {
          let parsed = [];
          for (let sale_item of json) {
            parsed.push(Fare.fromObject(sale_item))
          }

          // We need to process some logic the first time we consume the feed.
          if (this.isFirstRun) {
            this.isFirstRun = false;
            if (!parsed.length && this.saleEventName !== '') {
              // If on the first run + the sale event name filter is set and we
              // get no results, kill the sale event filter and query sales again.
              this.saleEventName = '';
              this.fetchJson();
              return;
            }
          }
          this.json = parsed;
          this.loading = false;
        })
    },
    fetchOrigins(callback) {
      let cachedOrigins = sessionStorage.getItem('originsList');
      if (cachedOrigins) {
        this.flightOrigins = this.cleanOrigins(JSON.parse(cachedOrigins));

        if (callback) {
          callback();
        }
      }
      else {
        let utils_path = this.basePath ?? this.get_utils_path();
        fetch(utils_path + 'activeOrigins.php', { cache: "force-cache" })
          .then(response => response.json())
          .then(json => {
            this.flightOrigins = this.cleanOrigins(json);
            sessionStorage.setItem('originsList', JSON.stringify(json));
            if (callback) {
              callback();
            }
          });
      }
    },
    cleanOrigins(origins) {
      const excludeOrigins = this.getExcludeOrigins();
      excludeOrigins.forEach((item) => {
        if (item in origins) {
          delete origins[item];
        }
      });
      return origins;
    },
    // Set the default iata, origin name when the component loads
    setDefaults() {
      var keys = Object.keys(this.flightOrigins);
      if (keys.length) {
        for (var iata in this.flightOrigins) {
          let origin = this.flightOrigins[iata];
          if (origin.iata === this.defaultOrigin) {
            this.updateOrigin(origin.iata, origin.name);
          }
        }
      }
    },
    getFares() {
      this.json;
    },
    updateOrigin(iata, name) {
      this.loading = true;
      this.currentOrigin = iata;
      this.currentOriginName = name;
      this.fetchJson();
    },

    hasHorizontalScrollbar(element) {
      return element.scrollWidth > element.clientWidth;
    },
    setSpecialsContainerShadow() {
      const flightSpecialsContainer = document.querySelector('.flight-specials-container');
      const flightSpecials = document.querySelector('.flight-specials');

      if (flightSpecialsContainer && flightSpecials) {
        const hasScrollbar = this.hasHorizontalScrollbar(flightSpecials);
        const scrollLeft = flightSpecials.scrollLeft;
        const scrollWidth = flightSpecials.scrollWidth;
        const clientWidth = flightSpecials.clientWidth;
        const scrollableWidth = scrollWidth - clientWidth;

        if (hasScrollbar) {
          flightSpecialsContainer.classList.toggle('is-scrolled-left', scrollLeft === 0);
          flightSpecialsContainer.classList.toggle('is-scrolled-right', scrollLeft === scrollableWidth);
          flightSpecialsContainer.classList.toggle('is-scrolled-middle', scrollLeft > 0 && scrollLeft < scrollableWidth);
        } else {
          flightSpecialsContainer.classList.remove('is-scrolled-left', 'is-scrolled-right', 'is-scrolled-middle');
        }
      }
    },
    debounce(func, wait) {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
    changeDefaultOrigin(embeddedContainer) {
      if (embeddedContainer) {
        const embeddedContainerOrigin = embeddedContainer.getAttribute('data-default-origin');
        // console.log('data-my-attribute:', embeddedContainerOrigin);
        if (embeddedContainerOrigin.toUpperCase() !== this.defaultOrigin) {
          this.defaultOrigin = embeddedContainerOrigin.toUpperCase();
          this.fetchOrigins(this.setDefaults);
        }
      }
    },
    showDialog(fare) {
      this.dialogFare = fare;
      this.dialogActive = true;
    },
    closeDialog(fare) {
      this.dialogFare = fare;
      this.dialogActive = false;
    }
  },
  mounted() {
    // Check for initial scrollbar visibility
    const flightSpecialsContainer = document.querySelector('.flight-specials');
    this.debouncedScrollListener = this.debounce(this.setSpecialsContainerShadow, 200);
    flightSpecialsContainer.addEventListener('scroll', this.debouncedScrollListener);

    // Observe changes to flightSpecials content
    const observer = new MutationObserver(() => {
      this.setSpecialsContainerShadow();
    });

    const embeddedContainerRoot = this.$el;
    const embeddedFlighsContainerObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // Access the current mutation target element
        const currentTargetElement = mutation.target;

        // Check if the mutation is related to the desired attribute
        if (mutation.attributeName === 'data-default-origin') {
          // Call the function with the current target element
          this.changeDefaultOrigin(currentTargetElement);
        }
      });
    });

    const config = { childList: true, subtree: true };
    observer.observe(flightSpecialsContainer, config);

    embeddedFlighsContainerObserver.observe(embeddedContainerRoot, {
      attributes: true,
      attributeOldValue: true
    });
  },
  created() {
    // Set data from props setting the correct types as data props are always
    // passed in as strings.
    this.defaultOrigin = this.dataDefaultOrigin.toUpperCase();
    this.saleEventName = this.dataSaleEvent;
    this.limit = Number(this.dataLimit);
    this.maxPrice = Number(this.dataMaxPrice);
    this.minPrice = Number(this.dataMinPrice);
    this.hideFilter = this.dataHideFilter === '1';
    this.title = this.dataTitle;

    // Upon component creation: get active origins, set the active IATA & retrieve fares
    this.fetchOrigins(this.setDefaults);
  }
}

</script>

<style lang="scss">
@import './styles/variables';


.va-embedded-flight-specials {
  //all: initial; // Reset CSS for the embedded App
  font-size: 16px;
  line-height: normal;
  font-family: $font-family;
  max-width: 70em;
  min-width: $tab-breakpoint;
  margin: 0 auto;

  @include bp('tab') {
    min-width: $mob-breakpoint;
  }

  @include bp('small') {
    min-width: 100%;
  }

  * {
    box-sizing: border-box;
  }

  h3 {
    line-height: normal;
  }

  h4 {
    margin-bottom: 1.33em;
    line-height: normal;

    @include bp('small') {
      margin-bottom: 0.88em;
    }
  }

  p {
    margin: 1em 0;
    line-height: normal;
  }

  .sp-tab {
    display: none;

    @include bp('tab') {
      display: block !important;
    }
  }

  .sp-mob {
    display: none;

    @include bp('small') {
      display: block !important;
    }
  }

  .flight-specials-container {
    &[class*='is-scrolled'] {
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        height: calc(100% - 22px);
        width: rem(24px);
        z-index: 9;
        opacity: .1;
        transition: opacity .3s ease-in-out;
      }

      &:before {
        left: 0;
        background: linear-gradient(to right, #5D5C5F 0%, rgba(93, 92, 95, 0.00) 100%);
      }

      &:after {
        right: 0;
        background: linear-gradient(to left, #5D5C5F 0%, rgba(93, 92, 95, 0.00) 100%);
      }
    }

    &.is-scrolled-left {
      &:before {
        opacity: 0;
      }
    }

    &.is-scrolled-right {
      &:after {
        opacity: 0;
      }
    }
  }

  .flight-specials {
    display: flex;
    gap: rem(24px);
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 16px;
    @include custom-scrollbar;

    > .standard-cards:first-child {
      margin-left: rem(-12px);
    }

    > .standard-cards:last-child {
      margin-right: rem(-12px);
    }

    > .image-cards:first-of-type {
      margin-left: rem(-24px);
    }

    > .image-cards:last-of-type {
      margin-right: rem(-24px);
    }

    &:before,
    &:after {
      content: '';
      margin: auto;
    }
  }

  .specials-empty {
    display: inline-block;
    position: relative;
    max-width: 10.9375em;
    text-align: center;
    vertical-align: top;

    .special-empty-info {
      padding-top: 4px;

      @include bp("small") {
        padding-top: 0px;
      }
    }

    @include bp('small') {
      width: 100%;
      max-width: 100%;
      display: flex;
      text-align: left;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      .specials-empty-image {
        border-style: solid;
        background-color: $va-red;
      }

      .specials-empty-price-tag {
        fill: #fff;
      }

      .special-empty-info {
        .specials-empty-title {
          color: $va-purple-dark;
          background-color: $va-red;
          border-radius: 4px;
          transition: background .3s, color .3s;
        }

        .specials-empty-signup {
          text-decoration: none;
        }
      }
    }
  }

  .specials-empty-image {
    border: 2px dashed $va-red;
    height: 10.9375em;
    width: 10.9375em;
    border-radius: 50%;
    position: relative;
    transition: background .3s, color .3s;

    @include bp('small') {
      min-width: 8.125em;
      width: 8.125em;
      height: 8.125em;
      margin-right: 5%;
    }
  }

  .specials-empty-price-tag {
    position: absolute;
    top: 35%;
    left: 35%;
    width: 4em;
    height: 4em;
    fill: $va-red;

    @include bp('small') {
      width: 2.75em;
      height: 2.75em;
    }
  }

  h3.specials-empty-title {
    color: $va-purple-dark;
    font-size: 1.5em;
    font-weight: 800;
    line-height: normal;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    @include bp("tab") {
      margin-top: 1em;
      font-size: 1.3em;
    }

    @include bp('small') {
      font-size: 1.3em;
      margin-top: 1.15em;
      margin-bottom: 0.5em;
      margin-left: -0.25em;
      max-width: 8em;
    }
  }

  .specials-empty-signup {
    color: $va-red;
    font-size: .75em;
    font-weight: bold;
    text-decoration: underline;
  }

  &.oneClick {
    max-width: 67.5em;

    .flight-specials-container {

      &:before,
      &:after {
        content: none;
      }
    }
  }
}

.flight-special-wrapper:not(.no-specials-wrapper) {
    flex: 0 0 254px;
  }

#specials-controls {
  margin-bottom: 1em;

  h4 {
    color: $va-purple;
    font-size: 1.875em;
    font-weight: 800;
  }
}

.all-specials-link-wrapper {
  display: flex;
  align-items: center;
}

#all-specials-link {
  display: block;
  margin: 2em auto 2.5em auto;
  background-color: $va-red;
  text-align: center;
  color: #fff;
  padding: 8px 24px;
  transition: background-color .25s ease-in-out, box-shadow .25s ease-in-out;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-decoration: none;
  border-radius: 0.25em;

  @include bp('small') {
    margin-top: 1.5em;
    margin-left: 0;
  }
  
  &:hover {
    background-color: $va-red-hover;
  }

  &:focus {
    box-shadow: 0px 0px 0px 4px #FEF3F3;
    background-color: $va-red;
  }

  &:active {
    background-color: $va-red-active;
    box-shadow: none;
  }
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.allSpecialsLinkWrapper {
  display: flex;

  #allSpecialsLink {
    display: block;
    margin-top: 1.5em;
    background-color: $white;
    text-align: center;
    color: $va-red;
    padding: 12px 24px;
    transition: background-color .25s ease-in-out, box-shadow .25s ease-in-out, color .25s ease-in-out;
    font-family: $font-heading;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid $va-red;
    border-radius: 0.25em;
    outline: none;
    position: relative;

    @include bp('small') {
      margin-top: 1.5em;
    }

    &:hover {
      background-color: $vaRedHoverNew;
    }

    &:focus,
    &:focus-visible {
      background-color: $vaRedHoverNew;
      outline: 1px solid $va-red;
      outline-offset: 0.25em; 
    }

    &:active {
      background-color: $vaRedActiveNew;
    }
  }
}

.flightSpecialWrapper.empty {
  background-color: $va-pink;
  border-radius: .5em;
  width: 100%;
  max-height: 20em; // 320px

  .specialWrapperEmpty {
    min-height: 20em; // 320px
    height: 100%;
    padding: 1em 1em 1em 1.5em;
    min-width: 15.75em; // 252px
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .specialEmptyContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: center;

    img {
      width: 9.625em; // 154px
      height: 8.625em; // 138px
    }

    .specialsEmptyTitle {
      margin-top: 1em;
      margin-bottom: .25em;
      font-family: $font-heading;
      font-weight: 500;
      font-size: 1.3125em; // 21px
      line-height: 1.625em; //26px
    }

    .specialsEmptySignup {
      font-size: .75em;
      line-height: 1em;
      font-weight: 400;
    }
  }

  .specialCta a.fareLinkOverlay.fareLinkOverlay {
    padding: 10px;
    border-radius: 50%;
    width: auto;
    border: 0;
    cursor: pointer;
    background-color: rgba(225, 10, 10, 0.05) !important;
    transition: background-color 0.25s ease-in-out;
  }
}
</style>
