import Vue from "vue";
import App from "./App.vue";
import SkeletonScaffold from 'vue-ultimate-skeleton-cards';

const scripts = document.getElementsByTagName("script"),
  src = document.currentScript?.src || scripts[scripts.length-1].src;

const getBasePath = () => {
  const path_components = String(src).split('/');

  // Remove /widget.js
  if ('widget.js' !== path_components.splice(-1).pop()) {
    return;
  }

  // Remove https://
  if ('' === path_components[1]) {
    path_components.splice(0, 2);
  }

  if (!path_components.length) {
    return;
  }

  return '//'+ path_components.join('/') +'/';
}

Vue.config.devtools = true;

let toCamelCase = function(string) {
  return string.replace(/^([A-Z])|[\s-_](\w)/g, function(match, p1, p2) {
      if (p2) return p2.toUpperCase();
      return p1.toLowerCase();
  });
};

let getPropsFromVueInstance = function(instance) {
  let props = {};
  Array.prototype.forEach.call(instance.$el.attributes, (attr) => {
    props[toCamelCase(attr.name)] = attr.value;
  });
  return props;
};

function useActiveTests() {
  const activeTests = new Vue({
    data: { value: ['default'] },
    methods: {
      activateTestAndLearn(tests) {
        this.value = Array.from((new Set([...this.value, ...(Array.isArray(tests) ? tests : [tests])])))
      },
      deactivateTestAndLearn(tests) {
        const set = new Set(this.value);
    
        for (const test of Array.isArray(tests) ? tests : [tests]) {
          set.delete(test);
        }
    
        this.value = Array.from(set);
      },
      activateFromVueInstance(instance) {
        const testsAttr = instance.$el.dataset.activeTests;
        if (testsAttr) {
          const tests = testsAttr.split(',');
          this.activateTestAndLearn(tests);
        }
      }
    },
  });

  // Test activation via query parameter
  const params = (new URL(document.location)).searchParams;
  activeTests.activateTestAndLearn(params.getAll('test_and_learn'));

  return activeTests;
}

const initApp = async () => {
  Vue.use(SkeletonScaffold);

  const BASE_PATH = getBasePath();
  const SPECIALS_URL = await (await fetch(BASE_PATH +'json.php?url')).text();

  var widgets = document.getElementsByClassName('va-embedded-flight-specials');


  const activeTests = useActiveTests();

  // Add a globally available function to activate tests
  window.activateTestAndLearn = activeTests.activateTestAndLearn;
  // Provide a function to deactivate tests if required
  window.deactivateTestAndLearn = activeTests.deactivateTestAndLearn;

  window.activateTestAndLearnLoaded = true;
  window.dispatchEvent(new Event('activateTestAndLearnLoaded'));

  if (widgets.length) {
    for (let i = 0; i < widgets.length; i++) {
      new Vue({
        el: widgets[i],
        render(h) {
          // Activate tests from HTML attribute specified on the widget
          activeTests.activateFromVueInstance(this);
          // Since the main element is not a vue 'component' we need do some extra work to pass forward the 'props' into the main component
          // This will allow the 'props' to be specified on the main <div>
          return h(App, {props: getPropsFromVueInstance(this)});
        },
        provide: { basePath: BASE_PATH, specialsUrl: SPECIALS_URL, activeTests },
      });
    }
  }
  else {
    new Vue({
      el: "#app",
      render(h) {
        // Activate tests from HTML attribute specified on the widget
        activeTests.activateFromVueInstance(this);
        return h(App, {props: getPropsFromVueInstance(this)});
      },
      provide: { basePath: BASE_PATH, specialsUrl: SPECIALS_URL, activeTests },
    });  
  }
}

if (document.readyState == 'loading') {
  document.addEventListener('DOMContentLoaded', initApp);
}
else {
  initApp();
}